import React, {Component} from 'react';
import {connect} from 'react-redux'
import B2App from 'components/B2/B2App';
import {LOAD_B2_APP, CLOSE_B2_MESSAGE} from 'constants/B2ActionTypes';
import SweetAlert from 'react-bootstrap-sweetalert';
import {getAppNameFromPath} from 'util/B2Utils';
import CircularProgress from '@material-ui/core/CircularProgress';

import {processB2Event, processB2SecurityEvent, processDownloadAttachmentB2Event, processDownloadEWDSafeeEvent, processDownloadFormSafeeEvent} from 'actions/B2App';
import {Link} from 'react-router-dom';

class B2AppWithRedux extends Component {
    handleRequestClose = () => {
        const b2Event = {
            type: CLOSE_B2_MESSAGE,
            eventDetails: {}
        }
        this.props.processB2Event(b2Event);
    };

//    componentWillMount() {
//        /*if (this.props.app && this.props.app.attributes && this.props.app.attributes.appName){
//            const event = {
//                type: LOAD_B2_APP,
//                eventDetails: {
//                    historyApp: this.props.app.attributes.appName.value
//                }
//            }
//            this.props.processB2Event(event);
//        }else{
//            const event = {
//                type: LOAD_B2_APP,
//                eventDetails: {}
//            }
//            this.props.processB2Event(event);
//        }*/
//    }
//
//    /*componentDidUpdate(){
//        if (typeof this.props.redirectLink!='undefined' && this.props.redirectLink!=null){
//            this.props.history.push(this.props.redirectLink);
//        }
//    }*/
//
//    componentWillUnmount() {
//        //this.props.cleanup();
//    }

    render() {
        if (this.props.appLoader){ // as application being loaded, display a loading icon
            return <div key={'b2_appcontainer'} id={'b2_appcontainer'}
                        className="loader-view"
                        style={{
                            height:
                                this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)'
                        }}
                    >
                        <CircularProgress />
                    </div>
        }else if (typeof(this.props.app)!=='undefined' && this.props.app!==null){
            return <B2App key={'b2_appcontainer'} id={'b2_appcontainer'} {...this.props} match={this.props.match} uiDefinition={this.props.app} />
        }else if (this.props.b2Message && this.props.b2Message.code !== '404' && this.props.b2Message.display && this.props.b2Message.display.toLowerCase()==='popup'){
            return <SweetAlert key={'b2_appcontainer'} id={'b2_appcontainer'} show={typeof(this.props.b2Message) !== 'undefined'}
                type={this.props.b2Message.type}
                confirmBtnText={this.props.b2Message.btnText}
                confirmBtnBsStyle={this.props.b2Message.confirmBtnBsStyle}
                title={this.props.b2Message.title}
                onConfirm={this.handleRequestClose}
           >
                <span style={{wordBreak: "break-word"}}>{this.props.b2Message.message}</span>
            </SweetAlert>
        }else if (this.props.b2Message && this.props.b2Message.code === '404' && this.props.b2Message.message !== '404: Application home not found'){
            return <div key={'b2_appcontainer'} id={'b2_appcontainer'} className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                        <div className="page-error-content">
                            <div className="error-code mb-4 animated zoomInDown">{this.props.b2Message.code}</div>
                            <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                                {this.props.b2Message.message}
                            </h2>
                            <p className="text-center zoomIn animation-delay-20 animated">
                                <Link className="btn btn-primary" to="/">Go to Home</Link>
                            </p>
                        </div>
                    </div>
        }else if (this.props.b2Message && this.props.b2Message.code === '404' && this.props.b2Message.message === '404: Application home not found'){
            return <div key={'b2_appcontainer'} id={'b2_appcontainer'}
                    className="loader-view"
                    style={{
                        height:
                            this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)'
                    }}
                >
                    <CircularProgress />
                </div>
        }else{
            return <React.Fragment key={'b2_appcontainer'}></React.Fragment>
        }
    }
}

const mapStateToProps = ({b2State, settings, router}) => {
    const {width} = settings;
    const {pathname} = router.location;
    const {
        app,
        beans,
        datasources,
        lookups,
        queries,
        menuActions,
        toolbarActions,
        b2Message,
        user,
        actionPendingConfirmation,
        appLoader,
        queryLoader,
        drawerState,
        searchValue,
        longOperation,
        longOperationText
    } = b2State;

    return {
        width,
        pathname,
        app,
        beans,
        datasources,
        lookups,
        queries,
        menuActions,
        toolbarActions,
        b2Message,
        user,
        actionPendingConfirmation,
        appLoader,
        queryLoader,
        drawerState,
        searchValue,
        longOperation,
        longOperationText
    }
};

export default connect(mapStateToProps, {
    processB2Event,
    processB2SecurityEvent,
    processDownloadAttachmentB2Event,
    processDownloadEWDSafeeEvent,
    processDownloadFormSafeeEvent
})(B2AppWithRedux);